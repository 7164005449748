@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#root {
  height: 100vh;
  /* min-height: 100%; */
}

.grecaptcha-badge {
  box-shadow: unset !important;
}

.grecaptcha-logo>iframe {
  display: none !important;
}

.ant-radio-group,
.ant-checkbox {
  filter: hue-rotate(-45deg);
}

a.ant-typography {
  color: #008992;
}

a.ant-typography:hover {
  color: #3fa4ab;
}

.ant-btn {
  background-color: rgb(0, 137, 146);
  color: white;
  border-color: rgb(0, 137, 146);
}

.ant-btn:hover {
  background-color: rgba(0, 136, 146, 0.678);
  color: white;
  border-color: rgba(0, 136, 146, 0.678);
}

.ant-btn:active,.ant-btn:focus {
  background-color: rgb(0 136 146);
  color: white;
  border-color: rgb(0 136 146);
}


.App {
  /* border-radius: 40px; */
  /* height: 100%; */
  min-height: 100vh;
}

.Background-cover {
  width: calc(100%);
  min-height: 100vh;

}

@media screen and (max-width: 600px) {
  .App {
    border-radius: 0px;
  }

  .Background-cover {
    margin: 18px;
    width: calc(100% - 18px - 18px);
    border-radius: 32px;
    background-color: rgb(255 255 255 / 82%) !important;

  }

  .Background-cover>div {
    padding: 10px !important;
  }

  .part-par>div {
    padding: 0px !important;
    font-size: 12px !important;
  }

  .part-par>div>.anticon {
    font-size: 24px !important;
  }

  .ant-typography {
    font-size: 18px;
  }

  .Introduction-reference>.ant-typography {
    font-size: 12px;
  }

  select {
    height: 32px;
    font-size: 24px;
  }
}

.print-page {
  width: 21cm;
  /* height: 29.7cm; */
  padding: 10px;
}

.print-page * {
  font-family: 'Noto Sans TC', sans-serif;
}

.print-page table td {
  border: 1px black solid;
}

@page {
  size: auto;
  /* margin-top: 5cm; */
  margin: 0px 0px;
  /* margin-bottom: 30px; */
  /* margin-top: 30px; */
}

.print-page div.ant-typography,
.ant-typography p {
  margin-bottom: 0.7em;
}

.print-page td {
  padding-left: 0.3cm;
}


.lh-fix .ant-typography.lh-fix-tag {
  line-height: 0.5cm !important;
  margin-bottom: 0.5em !important;
}

.lh-fix table {
  line-height: 1.3em !important;
}


.annotationLayer{
  display: none;
}